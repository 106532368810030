@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    @font-face {
      font-family: Avenir;
      src: url(../public/Avenir-Regular.ttf);
    }
  
    html {
      font-family: 'Avenir', 'Work Sans', sans-serif !important;
    }
  
  }
  ul {
  list-style: initial; /* Use initial to restore the default list style */
}
  body {
    box-sizing: border-box;
    background-color: #FAFBFF;
    line-height:1;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .my-input::placeholder {
    font-style: italic;
    padding: 10px;
    color: #737E93;
  }
  .blinking-text {
    animation: blink-animation 1.5s steps(5, start) infinite;
  }
  
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#5057ff 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#5057ff);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}

.redirect-loading {
  width: calc(80px / cos(45deg));
  height: 14px;
  --c: #000 0 15px,#0000 0 20px;
  background: 
    repeating-linear-gradient(135deg,var(--c)) left top,
    repeating-linear-gradient( 45deg,var(--c)) left bottom;
  background-size:  200% 50%;
  background-repeat: no-repeat;
  animation: l4 2s infinite linear;
}

@keyframes l13{ 
  100%{transform: rotate(1turn)}
}

@keyframes l4 {
  100% {background-position:top right,bottom right}
}